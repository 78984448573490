<template>
  <div class="login-container">
    <el-form ref="loginForm" class="login-form" :model="loginForm" autocomplete="on" :rules="loginRules">
      <div class="login-title"><h3>Login Form</h3></div>
      <el-form-item prop="phone">
        <i class="el-icon-user-solid"></i>
        <el-input
          ref="phone"
          v-model="loginForm.phone"
          placeholder="请输入手机号"
          name="phone"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>

      <el-form-item prop="password">
        <i class="el-icon-unlock"></i>
        <el-input
            ref="password"
            v-model="loginForm.password"
            type="password"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            autocomplete="on"
          />
      </el-form-item>

      <el-button  type="primary" class="login-button" :loading="loading"   @click.native.prevent="handleLogin" size="medium">登陆</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {},
      loginRules: {
        phone: [{ required: true, trigger: 'blur', pattern: /^1[345789]\d{9}$/, message: '请输入正确的手机号' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      loading: false
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if(valid) {
          // 登陆api
          this.loading = true
          this.$store.dispatch('auth/login', this.loginForm).then(() => {
            this.$router.push('/').catch(err => {})
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .login-container{
    height:100%;
    background:#2d3a4b;
    .login-form {
      position: relative;
      width: 450px;
      padding-top: 160px;
      margin: 0 auto;
      overflow: hidden;
    }
    .login-title{
      h3{
        font-size: 26px;
        color: #eee;
        margin: 0px auto 40px auto;
        text-align: center;
      }
    }
    .el-form-item{
      margin-bottom:25px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background:#283443;
      border-radius: 5px;
      color: #454545;
      i {
        color: #889aa4;
        margin-left:15px;
        font-size: 18px;
        vertical-align: middle;
      }
      .el-input {
        display: inline-block;
        height: 47px;
        width: 85%;
        input{
          background:transparent;
          border: 0px;
          -webkit-appearance: none;
          border-radius: 0px;
          padding: 12px 5px 12px 15px;
          color: #fff;
          height: 47px;
          caret-color: #fff;
          &:-webkit-autofill {
            box-shadow: 0 0 0px 1000px #283443 inset !important;
            -webkit-text-fill-color: #fff !important;
          }
        }
      }
    }
    .login-button{
      width:100%;
    }
  }
</style>